#wheel {
  position: absolute;
  height: 100vh;
  width: 100vh;
  top: 50%;
  left: 0.5%;
  transform: translate3d(-50%, -50%, 0);
}

.wheel-prize {
  animation: 16s title-shake ease-in-out infinite;
  background: #f7c017;
  border-radius: 3px;
  color: white;
  display: table;
  font-family: "Futura", Helvetica, Arial, sans-serif;
  font-size: 3vw;
  height: 10vw;
  left: 66%;
  line-height: 1.1em;
  overflow: visible;
  padding: 4px;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  transition: all 1s;
  top: 75%;
  width: 60vw;
  z-index: 100;

  &:before {
    content: "";
    display: block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border: 8px dotted #f7c017;
    position: absolute;
    left: -4px;
    top: -4px;
  }

  &--stopped {
    animation: 16s title-stopped ease-in-out infinite;
    left: 50%;
    top: 50%;
    z-index: 10;
  }

  .status_label {
    padding: 0 4px;
    display: table-cell;
    vertical-align: middle;
    background: #2b2b2b;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: all 1s;
    text-shadow: 0 0 10px #fff;

    &--stopped {
      color: #f7c017;
    }

  }

}


@keyframes title-shake {

  0% {
    transform: translate3d(-49%, -51%, 0) rotate(0deg);
  }

  33.3% {
    transform: translate3d(-51%, -49%, 0) rotate(1deg);
  }

  66.6% {
    transform: translate3d(-49%, -50%, 0) rotate(-1deg);
  }

  100% {
    transform: translate3d(-49%, -51%, 0) rotate(0deg);
  }
}

@keyframes title-stopped {

  0% {
    transform: translate3d(-49%, -51%, 0) rotate(0deg) scale(1);
  }

  33.3% {
    transform: translate3d(-51%, -49%, 0) rotate(1deg) scale(1.5);
  }

  66.6% {
    transform: translate3d(-49%, -50%, 0) rotate(-1deg) scale(1.3);
  }

  100% {
    transform: translate3d(-49%, -51%, 0) rotate(0deg) scale(1);
  }

}

