.title {
  position: fixed;
  left: 66.66666666%;
  top: 70%;
  width: 40%;
  height: auto;
  animation: 16s title-shake ease-in-out infinite;
}

@keyframes title-shake {

  0% {
    transform: translate3d(-49%, -51%, 0) rotate(0deg);
  }

  33.3% {
    transform: translate3d(-51%, -49%, 0) rotate(1deg);
  }

  66.6% {
    transform: translate3d(-49%, -50%, 0) rotate(-1deg);
  }

  100% {
    transform: translate3d(-49%, -51%, 0) rotate(0deg);
  }

}
