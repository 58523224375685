.hat {
  height: auto;
  position: absolute;
  left: 66.66666666%;
  top: 40%;
  width: 60vh;
  animation: 7s shake ease-in-out infinite;
}

@keyframes shake {

  0% {
    transform: translate3d(-49.5%, -50.5%, 0) rotate(0deg);
  }

  33.3% {
    transform: translate3d(-50.5%, -52%, 0) rotate(1deg);
  }

  66.6% {
    transform: translate3d(-49%, -50%, 0) rotate(-1deg);
  }

  100% {
    transform: translate3d(-49.5%, -50.5%, 0) rotate(0deg);
  }

}
