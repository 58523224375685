.background {
  background-color: #ffffff;
  position: fixed;
  height: 400vh;
  width: 400vw;
  top: 50%;
  left: 66.66666666%;
  animation: 400s rotate ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
    fill: #2b80b5;
  }
  50% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
    fill: #709bb5;
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
    fill: #2b80b5;
  }
}
